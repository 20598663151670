import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决replace重复点击报错问题
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

// 解决push重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        redirect: 'marKet',
        children: [{
                path: '/merChant',
                name: 'merChant',
                component: () => import('../views/merChant.vue'),
                meta: {
                    showBackButton: false
                }
            },
            {
                path: '/marKet',
                name: 'marKet',
                component: () => import('../views/marKet.vue'),
                meta: {
                    showBackButton: false
                }
            },
            {
                path: '/systemSet',
                name: 'systemSet',
                component: () => import('../views/systemSet.vue'),
                meta: {
                    showBackButton: false
                }
            },
            {
                path: '/bindBox',
                name: 'bindBox',
                component: () => import('../views/bindBox.vue'),
                meta: {
                    showBackButton: true
                }
            },
            {
                path: '/awardSelect',
                name: 'awardSelect',
                component: () => import('../views/awardSelect.vue'),
                meta: {
                    showBackButton: true
                }
            },
            {
                path: '/lottery',
                name: 'lottery',
                component: () => import('../views/lottery.vue'),
                meta: {
                    showBackButton: true
                }
            },
            {
                path: '/winCount',
                name: 'winCount',
                component: () => import('../views/winCount.vue'),
                meta: {
                    showBackButton: true
                }
            },
            {
                path: '/winCounts',
                name: 'winCounts',
                component: () => import('../views/winCounts.vue')
            },
            {
                path: '/gerateCode',
                name: 'gerateCode',
                component: () => import('../views/gerateCode.vue'),
                meta: {
                    showBackButton: true
                }
            },
            {
                path: '/getCode',
                name: 'getCode',
                component: () => import('../views/getCode.vue'),
                meta: {
                    showBackButton: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入ElementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import axios from 'axios' // axios http请求库
// axios.defaults.baseURL = 'https://idiancan.upcloud-bj.cn' // 设置默认请求的url
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.prototype.$imgUrl = 'https://idiancan.upcloud-bj.cn'

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
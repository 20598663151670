<template>
    <div id="app">
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isRouterAlive: true
            }
        },
        created() {
            // // 在页面加载时检测是否是初次加载或者强制刷新
            // if (performance.navigation.type === 1) {
            //     // 1 表示页面是由于点击浏览器的刷新按钮或者 location.reload() 方法刷新的
            //     this.$router.replace('/index')
            // }
        },
        methods: {
            reload() {
                this.isRouterAlive = false
                // 在下次dom更新循环结束之后执行延迟回调。数据更新之后立即使用这个方法，获得更新后的dom
                this.$nextTick(function() {
                    this.isRouterAlive = true
                })
            }
        },
        // 将该方法provide出去
        provide() {
            return {
                reload: this.reload
            }
        }
    }
</script>

<style>
    body {
        margin: 0;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /* 去除文本输入框输入样式 */
    input[type=text]:focus {
        outline: none;
    }

    /* 去除密码去除框输入样式 */
    input[type=password]:focus {
        outline: none;
    }
</style>